import { graphql } from 'gatsby'
import { H1, Label } from 'common'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GetInTouch, Aurora } from 'components'
import { React, Helmet, useStore, styled } from 'x'

const Wrapper = styled.div`
  padding: 100px 50px 50px;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }
`

const AuroraWrapper = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  overflow: hidden;
`

const Article = props => {
  const data = props.data.mdx

  return (
    <>
      <Helmet htmlAttributes={{ 'lang': props.pageContext.language }}>
        <title>{ data.article.title }</title>
        <meta name="description" content={ data.article.summary } />
      </Helmet>
      <AuroraWrapper>
        <Aurora header={ true }/>
      </AuroraWrapper>
      <Wrapper>
        <Label>Article</Label>
        <H1>{ data.article.title }</H1>
        <MDXRenderer>
          { data.body }
        </MDXRenderer>
      </Wrapper>
      <GetInTouch/>
    </>
  )
}

export const pageQuery = graphql`
  query ArticleQuery($path: String!) {
    mdx: mdx(frontmatter: { path: { eq: $path } }) {
      id
      excerpt
      timeToRead
      tableOfContents
      wordCount {
        words
        sentences
        paragraphs
      }
      headings {
        value
        depth
      }
      article: frontmatter {
        title
        path
        summary
      }
      body
    }
  }
`

export default Article
